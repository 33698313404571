import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100px;
  background-color: #0e0e2c;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  font-size: 14px;
  color: white;
`;
