import React from "react";
import "./stream.scss";

const Stream = () => {
  return (
    <div className="streamContainer" id="stream">
      <div className="imgContainer">
        <img src="./assets/stream.png" alt="" />
      </div>
      <div className="streamWrapper">
        <iframe
          title="ozkan"
          src="https://player.twitch.tv/?channel=burakoyunda&parent=burakoyunda.com"
          frameborder="0"
          allowfullscreen="true"
          scrolling="no"
          height="100%"
          width="100%"
          className="youtube"
        ></iframe>
      </div>
    </div>
  );
};

export default Stream;
