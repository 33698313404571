import React, { useEffect } from "react";
import { Container, Image, Wrapper } from "./Sponsors.styles";
import { sponsorLogo } from "../../data";
//Animate on scroll
import Aos from "aos";
import "aos/dist/aos.css";

const Sponsors = () => {
  return (
    <Container id="referans">
      {sponsorLogo.map((data) => (
        <Wrapper>
          <Image src={data.link} />
        </Wrapper>
      ))}
    </Container>
  );
};

export default Sponsors;
